import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Card, CardContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Sidebar from '../SideBarMl';
import CodeSnippet from '../CodeSnippet'; // Assuming you have a CodeSnippet component for displaying code

const PCAInfo = () => {
  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
      <div className="app-main">
        <Sidebar />
        <div className="app-main-outer">
          <div className="app-main-inner">
            <div className="page-title-actions px-3 d-flex">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                  <li className="breadcrumb-item active" aria-current="page">AI Model</li>
                </ol>
              </nav>
            </div>
            <div className="row" id="deleteTableItem">
              <div className="col-md-12">
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <Container>
                        <Typography variant="h4" gutterBottom className='text-center mt-4'>
                          <strong>Principal Component Analysis (PCA)</strong>
                        </Typography>

                        {/* Overview Section */}
                        <Card variant="outlined" sx={{ mb: 2 }}>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Overview
                            </Typography>
                            <Typography variant="body1" paragraph>
                              Principal Component Analysis (PCA) is a statistical technique used to simplify datasets by reducing their dimensions while retaining important information. It transforms correlated variables into a set of linearly uncorrelated ones called principal components. These components are ordered by the amount of variance they explain in the original data, allowing for dimensionality reduction while preserving as much variance as possible. PCA is widely used in exploratory data analysis and feature extraction, aiding in visualizing high-dimensional data and improving the performance of machine learning algorithms by eliminating redundant or noisy features.
                            </Typography>
                          </CardContent>
                        </Card>

                        {/* Working Principles of PCA */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Working Principles of PCA</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              <strong>Data Collection:</strong> Gather a dataset that includes features (input variables). PCA does not require target labels. For example, in image processing, features could include pixel values.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Data Preprocessing:</strong>
                              <ul>
                                <li>Cleaning: Handle missing values, remove duplicates, and correct errors.</li>
                                <li>Normalization/Standardization: Scale the features to have zero mean and unit variance, as PCA is sensitive to the scale of the data.</li>
                              </ul>
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Covariance Matrix Computation:</strong> Calculate the covariance matrix of the standardized features to understand how the features vary with respect to each other.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Eigenvalue and Eigenvector Calculation:</strong> Compute the eigenvalues and eigenvectors of the covariance matrix. Eigenvectors determine the direction of the principal components, while eigenvalues determine their magnitude.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Principal Component Selection:</strong> Rank the eigenvectors based on their corresponding eigenvalues in descending order. Select the top k eigenvectors to form a new feature space with k principal components.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Transformation:</strong> Project the original data onto the new feature space formed by the selected principal components. This results in a reduced set of features while retaining most of the data's variance.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Evaluation:</strong> Assess the effectiveness of PCA by measuring the variance explained by the principal components. The explained variance ratio indicates the proportion of the dataset's variance that is captured by each principal component.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Deployment:</strong> Use the transformed features for further analysis or as input to other machine learning models.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Python Code Example:</strong>
                            </Typography>
                            <CodeSnippet
                              language="python"
                              code={`from sklearn.decomposition import PCA
from sklearn.preprocessing import StandardScaler
import numpy as np

# Sample Data
data = np.array([[2.5, 2.4],
                 [0.5, 0.7],
                 [2.2, 2.9],
                 [1.9, 2.2],
                 [3.1, 3.0],
                 [2.3, 2.7],
                 [2, 1.6],
                 [1, 1.1],
                 [1.5, 1.6],
                 [1.1, 0.9]])

# Standardize the Data
scaler = StandardScaler()
data_std = scaler.fit_transform(data)

# Apply PCA
pca = PCA(n_components=2)
principal_components = pca.fit_transform(data_std)

print('Explained Variance Ratio:', pca.explained_variance_ratio_)
print('Principal Components:\n', principal_components)`}
                            />
                            <Typography variant="body2" paragraph>
                              In this code snippet, PCA is applied to a small dataset. The data is first standardized, then transformed into two principal components, with the explained variance ratio indicating the importance of each component.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* When to Use PCA */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">When to Use PCA</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              PCA is particularly useful in the following scenarios:
                              <ul>
                                <li>When the dataset has a large number of features and dimensionality reduction is needed.</li>
                                <li>When the features are highly correlated and you want to remove redundancy.</li>
                                <li>Examples include image processing, genome data analysis, and financial data analysis.</li>
                              </ul>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Why PCA is Important */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Why PCA is Important</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              PCA provides several key benefits:
                              <ul>
                                <li><strong>Dimensionality Reduction:</strong> Reduces the number of features, making the data easier to visualize and analyze.</li>
                                <li><strong>Noise Reduction:</strong> Eliminates noise by focusing on the most important features.</li>
                                <li><strong>Improved Performance:</strong> Enhances the performance of machine learning models by reducing computational complexity.</li>
                              </ul>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Common Steps in PCA */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Common Steps in PCA</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              PCA typically involves the following steps:
                              <ul>
                                <li>Standardization: Scale the features to have zero mean and unit variance.</li>
                                <li>Covariance Matrix Computation: Calculate the covariance matrix of the standardized features.</li>
                                <li>Eigenvalue and Eigenvector Calculation: Compute the eigenvalues and eigenvectors of the covariance matrix.</li>
                                <li>Principal Component Selection: Select the top k eigenvectors based on their eigenvalues.</li>
                                <li>Transformation: Project the original data onto the new feature space formed by the selected principal components.</li>
                              </ul>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* How PCA Works */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">How PCA Works</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              <strong>Input Features:</strong> The original features of the dataset.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Transformation:</strong> The original features are transformed into a new set of uncorrelated features (principal components).
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Output Features:</strong> The transformed features that capture most of the data's variance with fewer dimensions.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Where PCA is Applied */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Where PCA is Applied</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              PCA is widely used in various fields:
                              <ul>
                                <li><strong>Image Processing:</strong> Reducing the number of pixels while retaining the essential information.</li>
                                <li><strong>Genomics:</strong> Analyzing high-dimensional genetic data to identify significant patterns.</li>
                                <li><strong>Finance:</strong> Reducing the complexity of financial datasets for better analysis and prediction.</li>
                                <li><strong>Marketing:</strong> Simplifying customer data to identify key segments and trends.</li>
                              </ul>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PCAInfo;
