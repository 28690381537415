import React from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Sidebar from '../SideBarMl';
import CodeSnippet from '../CodeSnippet'; // Assuming you have a CodeSnippet component for displaying code

const DataVisualizationExplanation = () => {
  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
      <div className="app-main">
        <Sidebar />
        <div className="app-main-outer">
          <div className="app-main-inner">
            <div className="page-title-actions px-3 d-flex">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                  <li className="breadcrumb-item active" aria-current="page">AI Model</li>
                </ol>
              </nav>
            </div>
            <div className="row" id="deleteTableItem">
              <div className="col-md-12">
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <Container>
                        <Typography variant="h4" gutterBottom className="text-center mt-4">
                          <strong>Data Visualization Techniques: A Detailed Guide</strong>
                        </Typography>

                        {/* Overview Section */}
                        <Card variant="outlined" sx={{ mb: 2 }}>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Overview
                            </Typography>
                            <Typography variant="body1" paragraph>
                              Data visualization involves representing data graphically to explore and communicate insights effectively. It transforms complex datasets into visual forms such as charts, graphs, and maps, enhancing understanding and revealing patterns, trends, and relationships that might be obscured in raw data. Visualization techniques range from simple bar charts and scatter plots to sophisticated interactive dashboards and geographic information systems (GIS). By presenting information visually, data visualization helps stakeholders and analysts interpret data quickly, make informed decisions, and communicate findings persuasively. It plays a crucial role in fields like business intelligence, scientific research, and data-driven journalism, facilitating data exploration and storytelling with clarity and impact.
                            </Typography>
                          </CardContent>
                        </Card>

                        {/* Pie Chart */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">1. Pie Chart</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              <strong>Definition:</strong> A pie chart is a circular graph divided into slices to represent numerical proportions. Each slice's size is proportional to the quantity it represents.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Purpose:</strong> Pie charts are used to show how parts of a whole compare to each other. They are effective for illustrating percentages or proportions at a glance.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Example:</strong> Visualizing the distribution of sales by different product categories where each slice represents the percentage of total sales for that category.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>How It Works:</strong> Each slice represents a category or part of the whole dataset. The size of each slice is proportional to the value it represents relative to the total sum of values. Labels or percentages are often included to indicate exact values or proportions for each slice.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Python Code Example:</strong>
                            </Typography>
                            <CodeSnippet
                              language="python"
                              code={`import matplotlib.pyplot as plt

# Data to plot
labels = 'Category A', 'Category B', 'Category C', 'Category D'
sizes = [15, 30, 45, 10]
colors = ['gold', 'yellowgreen', 'lightcoral', 'lightskyblue']
explode = (0.1, 0, 0, 0)  # explode 1st slice

# Plot
plt.pie(sizes, explode=explode, labels=labels, colors=colors,
autopct='%1.1f%%', shadow=True, startangle=140)

plt.axis('equal')
plt.show()`}
                            />
                            <Typography variant="body2" paragraph>
                              In this example, a pie chart is created using Matplotlib in Python, displaying four categories with different proportions.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Bar Chart */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">2. Bar Chart</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              <strong>Definition:</strong> A bar chart uses rectangular bars to represent data values. The length or height of each bar is proportional to the value it represents.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Purpose:</strong> Bar charts are ideal for comparing quantities of discrete categories. They visually show relationships and comparisons between different groups of data.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Example:</strong> Comparing monthly sales figures for different products where each bar represents sales amount for a specific month.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>How It Works:</strong> Categories or groups are represented on the horizontal axis (x-axis). Values or quantities are represented on the vertical axis (y-axis). Each bar's height or length corresponds to the value it represents. Bar charts can be vertical (columns) or horizontal (bars).
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Python Code Example:</strong>
                            </Typography>
                            <CodeSnippet
                              language="python"
                              code={`import matplotlib.pyplot as plt

# Data
categories = ['Product A', 'Product B', 'Product C', 'Product D']
values = [10, 24, 36, 18]

# Plot
plt.bar(categories, values, color='blue')
plt.xlabel('Products')
plt.ylabel('Sales')
plt.title('Monthly Sales Data')
plt.show()`}
                            />
                            <Typography variant="body2" paragraph>
                              This example demonstrates how to create a simple bar chart using Matplotlib, comparing sales across different products.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Histogram */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">3. Histogram</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              <strong>Definition:</strong> A histogram is a graphical representation of the distribution of numerical data. It consists of bars that show the frequency of data points falling into intervals (bins).
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Purpose:</strong> Histograms help visualize the distribution of continuous data and identify patterns such as symmetry, skewness, or outliers.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Example:</strong> Displaying the distribution of student scores in a class where each bar represents the frequency of scores falling within a score range (interval).
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>How It Works:</strong> Data values are grouped into intervals or bins along the x-axis. The height of each bar represents the frequency or count of data points within that interval. Histograms are used to understand the spread, central tendency, and shape of data distribution.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Python Code Example:</strong>
                            </Typography>
                            <CodeSnippet
                              language="python"
                              code={`import matplotlib.pyplot as plt

# Data
scores = [55, 62, 63, 70, 75, 80, 85, 85, 90, 92, 95, 96, 98, 100]

# Plot
plt.hist(scores, bins=5, color='green', edgecolor='black')
plt.xlabel('Score Range')
plt.ylabel('Frequency')
plt.title('Distribution of Student Scores')
plt.show()`}
                            />
                            <Typography variant="body2" paragraph>
                              The histogram example above shows how to create a histogram in Python using Matplotlib to visualize the distribution of student scores.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Scatter Plot */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">4. Scatter Plot</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              <strong>Definition:</strong> A scatter plot uses Cartesian coordinates to display values for two variables as points. Each point represents a data point with values for both variables.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Purpose:</strong> Scatter plots show relationships or correlations between two variables. They help identify patterns and trends in data.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Example:</strong> Plotting heights against weights of individuals to examine if there's a correlation between height and weight.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>How It Works:</strong> Each axis represents a different variable (x-axis and y-axis). Data points are plotted as individual points on the graph. Scatter plots can show clusters, trends (positive or negative correlations), or the absence of relationships between variables.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Python Code Example:</strong>
                            </Typography>
                            <CodeSnippet
                              language="python"
                              code={`import matplotlib.pyplot as plt

# Data
heights = [150, 160, 165, 170, 175, 180, 185, 190]
weights = [55, 60, 63, 65, 70, 72, 78, 80]

# Plot
plt.scatter(heights, weights, color='red')
plt.xlabel('Height (cm)')
plt.ylabel('Weight (kg)')
plt.title('Height vs. Weight')
plt.show()`}
                            />
                            <Typography variant="body2" paragraph>
                              The scatter plot example shows how to visualize the relationship between height and weight using Matplotlib in Python.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Box Plot */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">5. Box Plot (Box-and-Whisker Plot)</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              <strong>Definition:</strong> A box plot summarizes the distribution of data points using quartiles (25th, 50th, and 75th percentiles). It provides a visual representation of variability and outliers in data.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Purpose:</strong> Box plots help visualize the range, median, and distribution of data. They are useful for comparing distributions across different groups or variables.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Example:</strong> Visualizing salary distributions across different departments where each box plot shows the minimum, maximum, median salary, and variability.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>How It Works:</strong> The box represents the interquartile range (IQR) which spans from the 25th percentile (Q1) to the 75th percentile (Q3). A line or whisker extends from the box to the minimum and maximum values within a certain range (often 1.5 times the IQR). Outliers beyond this range are shown as individual points.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Python Code Example:</strong>
                            </Typography>
                            <CodeSnippet
                              language="python"
                              code={`import matplotlib.pyplot as plt

# Data
salaries = [32000, 36000, 34000, 38000, 42000, 46000, 50000, 54000, 58000, 62000]

# Plot
plt.boxplot(salaries)
plt.ylabel('Salary ($)')
plt.title('Salary Distribution')
plt.show()`}
                            />
                            <Typography variant="body2" paragraph>
                              The box plot example illustrates how to use Matplotlib to visualize the distribution of salaries and identify any potential outliers.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Line Chart */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">6. Line Chart</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              <strong>Definition:</strong> A line chart displays data points connected by straight lines. It is used to visualize trends over time or continuous data.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Purpose:</strong> Line charts are ideal for showing trends, patterns, or changes in data over time. They are often used for time series analysis.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Example:</strong> Tracking monthly revenue growth over a year, where each point represents the revenue for a specific month.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>How It Works:</strong> Data points are plotted on the graph and connected by lines. The x-axis typically represents time or the independent variable, while the y-axis represents the dependent variable.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Python Code Example:</strong>
                            </Typography>
                            <CodeSnippet
                              language="python"
                              code={`import matplotlib.pyplot as plt

# Data
months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
revenue = [1000, 1200, 1300, 1500, 1600, 1700, 1750, 1800, 1900, 2100, 2200, 2500]

# Plot
plt.plot(months, revenue, marker='o')
plt.xlabel('Month')
plt.ylabel('Revenue ($)')
plt.title('Monthly Revenue Growth')
plt.show()`}
                            />
                            <Typography variant="body2" paragraph>
                              The line chart example demonstrates how to plot monthly revenue growth using Matplotlib, making it easy to see trends over time.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Heatmap */}
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">7. Heatmap</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body1" paragraph>
                              <strong>Definition:</strong> A heatmap is a graphical representation of data where individual values are represented as colors. It is used to visualize the magnitude of values across a matrix or a two-dimensional space.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Purpose:</strong> Heatmaps are effective for identifying patterns, correlations, and anomalies within datasets. They are commonly used in data analysis and visualization to compare data across variables.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Example:</strong> Visualizing the correlation matrix of different variables in a dataset, where each cell represents the correlation between two variables.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>How It Works:</strong> Data values are mapped to colors, with higher or lower values represented by different shades or hues. The x-axis and y-axis represent different categories or variables, and the color intensity indicates the magnitude of the data points.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              <strong>Python Code Example:</strong>
                            </Typography>
                            <CodeSnippet
                              language="python"
                              code={`import seaborn as sns
import matplotlib.pyplot as plt

# Sample Data
data = sns.load_dataset('flights').pivot('month', 'year', 'passengers')

# Plot
sns.heatmap(data, cmap='coolwarm', annot=True)
plt.title('Flight Passengers Heatmap')
plt.show()`}
                            />
                            <Typography variant="body2" paragraph>
                              This heatmap example shows how to visualize the number of passengers across different months and years using Seaborn in Python.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataVisualizationExplanation;
