import React, { useState } from 'react';
import {
  Container,
  Typography,
  Divider,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  Box,
} from '@mui/material';
import Sidebar from '../SideBarMl';
import CodeSnippet from '../CodeSnippet'; // Assuming you have a CodeSnippet component for displaying code

function Classification() {
  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
      <CssBaseline />
      <div className="app-main">
        <Sidebar />
        <div className="app-main-outer">
          <div className="app-main-inner">
            <div className="page-title-actions px-3 d-flex">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                  <li className="breadcrumb-item active" aria-current="page">AI Model</li>
                </ol>
              </nav>
            </div>
            <div className="row" id="deleteTableItem">
              <div className="col-md-12">
                <div className="card mb-5">
                  <div className="card-body">
                    <Container maxWidth="xl" spacing={2}>
                      <Typography variant="h4" gutterBottom align="center" mt={4}>
                        <strong>Classification: A Detailed Guide</strong>
                      </Typography>

                      <Typography variant="body1" paragraph mt={4}>
                        Classification is a supervised learning technique in machine learning. Its purpose is to predict a target variable that is categorical (i.e., it takes on a limited number of possible values, such as "spam" or "not spam"). The goal is to build a model that can accurately assign labels to new, unseen instances based on historical data.
                      </Typography>

                      <Divider />

                      <Typography variant="h5" gutterBottom mt={4}>
                        Working Principles of Classification
                      </Typography>
                      <Typography variant="body1" paragraph>
                        The classification process involves several key steps that ensure the model is both accurate and efficient:
                      </Typography>
                      <ul>
                        <li><strong>Data Collection:</strong> Collect a dataset with features (input variables) and a target variable (class labels).</li>
                        <li><strong>Data Preprocessing:</strong> Clean the data by handling missing values, removing duplicates, and normalizing features. Encode categorical variables if necessary.</li>
                        <li><strong>Feature Selection/Extraction:</strong> Identify and select the most relevant features, or create new ones, to improve model performance.</li>
                        <li><strong>Model Selection:</strong> Choose a classification algorithm such as Logistic Regression, Decision Trees, Random Forest, SVM, Naive Bayes, KNN, or Neural Networks.</li>
                        <li><strong>Training the Model:</strong> Use the training data to teach the model to map input features to target labels.</li>
                        <li><strong>Evaluation:</strong> Assess the model’s performance using metrics like Accuracy, Precision, Recall, and F1 Score.</li>
                        <li><strong>Hyperparameter Tuning:</strong> Adjust the model's hyperparameters to optimize its performance.</li>
                        <li><strong>Deployment:</strong> Deploy the trained model to make predictions on new, unseen data.</li>
                      </ul>

                      <Divider />

                      <Typography variant="h5" gutterBottom mt={4}>
                        Example Code: Implementing a Classification Model with Scikit-learn
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Let’s implement a classification model using the Random Forest algorithm in Python. Below is a simple example using Scikit-learn:
                      </Typography>
                      <CodeSnippet
                        language="python"
                        code={`from sklearn.datasets import load_iris
from sklearn.ensemble import RandomForestClassifier
from sklearn.model_selection import train_test_split
from sklearn.metrics import accuracy_score, classification_report

# Load the Iris dataset
data = load_iris()
X = data.data  # Features
y = data.target  # Target labels

# Split the data into training and test sets
X_train, X_test, y_train, y_test = train_test_split(X, y, test_size=0.3, random_state=42)

# Initialize and train the model
model = RandomForestClassifier(n_estimators=100, random_state=42)
model.fit(X_train, y_train)

# Make predictions
y_pred = model.predict(X_test)

# Evaluate the model
accuracy = accuracy_score(y_test, y_pred)
print(f'Accuracy: {accuracy:.2f}')
print(classification_report(y_test, y_pred, target_names=data.target_names))`}
                      />
                      <Typography variant="body2" paragraph>
                        In this example, we used the Iris dataset, a classic dataset in machine learning, to train a Random Forest classifier. The model was evaluated using accuracy and a classification report, which includes precision, recall, and F1 score.
                      </Typography>

                      <Divider />

                      <Typography variant="h5" gutterBottom mt={4}>
                        Visualization: Understanding the Decision Boundary
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Visualizing the decision boundary of a classification model can provide insights into how the model is making its predictions. Below is an example using a 2D dataset:
                      </Typography>
                      <CodeSnippet
                        language="python"
                        code={`import numpy as np
import matplotlib.pyplot as plt
from sklearn.datasets import make_classification
from sklearn.ensemble import RandomForestClassifier

# Generate a synthetic 2D dataset
X, y = make_classification(n_samples=100, n_features=2, n_classes=2, n_informative=2, n_redundant=0, random_state=42)

# Train a Random Forest classifier
model = RandomForestClassifier(n_estimators=100, random_state=42)
model.fit(X, y)

# Create a mesh grid
x_min, x_max = X[:, 0].min() - 1, X[:, 0].max() + 1
y_min, y_max = X[:, 1].min() - 1, X[:, 1].max() + 1
xx, yy = np.meshgrid(np.arange(x_min, x_max, 0.01), np.arange(y_min, y_max, 0.01))

# Predict on the mesh grid
Z = model.predict(np.c_[xx.ravel(), yy.ravel()])
Z = Z.reshape(xx.shape)

# Plot the decision boundary
plt.contourf(xx, yy, Z, alpha=0.4)
plt.scatter(X[:, 0], X[:, 1], c=y, s=20, edgecolor='k')
plt.title('Decision Boundary of Random Forest')
plt.xlabel('Feature 1')
plt.ylabel('Feature 2')
plt.show()`}
                      />
                      <Typography variant="body2" paragraph>
                        This code generates a synthetic 2D dataset and trains a Random Forest classifier on it. The decision boundary is plotted to show how the classifier distinguishes between the two classes. Such visualizations can be incredibly useful for understanding how a model is making decisions.
                      </Typography>

                      <Divider />

                      <Typography variant="h5" gutterBottom mt={4}>
                        When to Use Classification
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Classification is most effective when the target variable is categorical, and the goal is to categorize data into distinct classes. This technique is widely used in:
                      </Typography>
                      <ul>
                        <li>Spam detection (e.g., classifying emails as spam or not spam)</li>
                        <li>Medical diagnosis (e.g., predicting whether a patient has a certain disease)</li>
                        <li>Image classification (e.g., recognizing objects in images)</li>
                        <li>Sentiment analysis (e.g., determining whether a review is positive or negative)</li>
                        <li>Fraud detection (e.g., identifying fraudulent transactions)</li>
                      </ul>

                      <Divider />

                      <Typography variant="h5" gutterBottom mt={4}>
                        Why Classification is Important
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Classification is crucial because it helps in making informed decisions by categorizing data into meaningful classes. It automates sorting processes and provides valuable insights into the relationships between variables. Additionally, classification models can be used to predict outcomes in various scenarios, making them indispensable tools in fields like finance, healthcare, and marketing.
                      </Typography>

                      <Divider />

                      <Typography variant="h5" gutterBottom mt={4}>
                        Common Algorithms for Classification
                      </Typography>
                      <Typography variant="body1" paragraph>
                        There are several algorithms commonly used in classification tasks:
                      </Typography>
                      <ul>
                        <li><strong>Logistic Regression:</strong> Suitable for binary classification with linear decision boundaries.</li>
                        <li><strong>Decision Trees:</strong> Easy to interpret and can handle both numerical and categorical data.</li>
                        <li><strong>Random Forest:</strong> Combines multiple decision trees to reduce overfitting and improve accuracy.</li>
                        <li><strong>Support Vector Machines (SVM):</strong> Effective in high-dimensional spaces, particularly with clear margins of separation.</li>
                        <li><strong>Naive Bayes:</strong> Assumes feature independence and is particularly effective with small datasets.</li>
                        <li><strong>K-Nearest Neighbors (KNN):</strong> A simple, intuitive algorithm that works well with smaller datasets.</li>
                        <li><strong>Neural Networks:</strong> Powerful models capable of handling complex, non-linear relationships in data.</li>
                      </ul>

                      <Divider />

                      <Typography variant="h5" gutterBottom mt={4}>
                        How Classification Works
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Classification models learn to map input features to target labels through a learning process. Here's a deeper look into the process:
                      </Typography>
                      <ul>
                        <li><strong>Input Features:</strong> These are the characteristics or properties of the instances provided as input to the model.</li>
                        <li><strong>Learning Process:</strong> During training, the model adjusts its parameters based on the training data to learn the mapping between input features and target labels.</li>
                        <li><strong>Prediction:</strong> Once trained, the model can predict the class label for new, unseen instances based on the learned mapping.</li>
                      </ul>

                      <Divider />

                      <Typography variant="h5" gutterBottom mt={4}>
                        Where Classification is Applied
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Classification models are widely used across various industries, including:
                      </Typography>
                      <ul>
                        <li><strong>Spam Detection:</strong> Classifying emails as spam or not spam.</li>
                        <li><strong>Medical Diagnosis:</strong> Identifying the presence of a disease based on symptoms.</li>
                        <li><strong>Image Classification:</strong> Categorizing images into different classes, such as identifying animals in pictures.</li>
                        <li><strong>Sentiment Analysis:</strong> Determining the sentiment of text data, such as classifying reviews as positive or negative.</li>
                        <li><strong>Fraud Detection:</strong> Identifying fraudulent transactions in financial systems.</li>
                      </ul>

                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Classification;
